import React, { useState } from "react";
import Img, { FluidObject } from "gatsby-image";
import { Carousel } from "react-responsive-carousel";

import Container from "./Container";
import { ImagePreview } from "./PreviewContext";

export interface SlideshowProps {
    title: string;
    images: FluidObject[];
}

const Slideshow: React.FC<SlideshowProps> = ({ title, images }) => {
    const [index, setIndex] = useState(0);

    return (
        <Container title={title} className="slideshow">
            <Carousel
                className="slideshow__inner"
                selectedItem={index}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                showArrows={false}
                onChange={i => i !== index && setIndex(i)}
                infiniteLoop
            >
                {
                    images.map((image, i) => (
                        <ImagePreview key={i} fluid={image} />
                    ))
                }
            </Carousel>
            <ul className="thumbs">
                {
                    images.map((image, i) => (
                        <li
                            key={i}
                            className={`thumb ${index === i ? "selected" : ""}`.trim()}
                            onClick={() => setIndex(i)}
                        >
                            <Img fluid={image} />
                        </li>
                    ))
                }
            </ul>
        </Container>
    );
};

export default Slideshow;

