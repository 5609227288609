import React from "react";

export interface FooterProps {
    title: string;
    text: string;
}

const Footer: React.FC<FooterProps> = ({ title, text, children }) => {
    return (
        <footer className="footer">
            <div className="footer__inner">
                <section className="footer__info">
                    <h1 className="footer__info__title">{title}</h1>
                    <p className="footer__info__text">{text}</p>
                </section>
                {children}
            </div>
        </footer>
    );
};

export default Footer;
