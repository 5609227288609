import React from "react";

import Stitched from "./Stitched";

export interface ColorCardProps {
    name: string;
    color: string;
    hex: string;
    text?: string;
}

const ColorCard: React.FC<ColorCardProps> = ({ name, color, hex, text }) => {
    return (
        <Stitched
            className="color-card"
            style={{
                backgroundColor: hex,
                color: text,
            }}
        >
            <label className="color-card__title">{name}</label>
            <div className="color-card__info">
                <p className="color-card__info__color">{color}</p>
                <p className="color-card__info__hex">{hex}</p>
            </div>
        </Stitched>
    );
};

export default ColorCard;

