import React from "react";
import Img, { FluidObject } from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Stitched from "./Stitched";

import Tent from "../../static/svg/Yuru_R1_Tent.svg";
import Scarf from "../../static/svg/GMK_Yuru_Scarf_275.svg";

export interface HeroProps {
    title: string;
    text?: string;
    image: FluidObject;
}

const Hero: React.FC<HeroProps> = ({ title, text, image }) => {
    return (
        <header className="hero">
            <Img className="hero__background" fluid={image} />
            <div className="hero__content">
                <div className="hero__content--overlay">
                    <Scarf />
                    <Stitched className="hero__content--overlay__inner">
                        <h1 className="hero__content__title">{title}</h1>
                        {text && <p className="hero__content__text">{text}</p>}
                    </Stitched>
                </div>
            </div>
            <a className="hero__button" href="#kits" aria-label="Scroll down">
                <Tent />
            </a>
        </header>
    );
};

export default Hero;

