import React, { ReactNode, AnchorHTMLAttributes } from "react";

export interface LinkButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    className?: string;
    icon?: ReactNode;
}

const LinkButton: React.FC<LinkButtonProps> = ({ icon, children, className, ...rest }) => {
    return (
        <a {...rest} className={`link-button ${className || ""}`.trim()}>
            <div className="link-button__inner">{children}</div>
            {
                icon && <div className="link-button__icon">
                    {icon}
                </div>
            }
        </a>
    );
};

export default LinkButton;
