import React, { ReactNode, useState, createContext } from "react";
import ReactModal from "react-modal";
import Img, { GatsbyImageProps, FluidObject } from "gatsby-image";

export interface PreviewContextProps {
    setOpen(open: boolean): void;
    setImage(obj: FluidObject): void;
}

const PreviewContext = createContext<PreviewContextProps>({
    setOpen(_) {},
    setImage(_) {},
});

export const PreviewProvider: React.FC = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState<FluidObject>(null);

    return (
        <>
            <PreviewContext.Provider
                value={{
                    setOpen,
                    setImage,
                }}
            >
                <ReactModal
                    closeTimeoutMS={100}
                    isOpen={open}
                    style={{
                        overlay: {
                            zIndex: 1,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                        },
                        content: {
                            padding: 0,
                            background: undefined,
                            border: undefined,
                        },
                    }}
                    shouldCloseOnEsc
                    shouldCloseOnOverlayClick
                >
                    <a
                        className="image-preview--modal"
                        onClick={() => setOpen(false)}
                    >
                        <Img
                            fluid={image}
                            style={{ height: "100%" }}
                            imgStyle={{ objectFit: "contain" }}
                        />
                    </a>
                </ReactModal>
                {children}
            </PreviewContext.Provider>
        </>
    );
};

export interface ImagePreviewProps extends GatsbyImageProps {
    fluid: FluidObject;
    full?: FluidObject;
}

export const ImagePreview: React.FC<ImagePreviewProps> = (props) => {
    return (
        <PreviewContext.Consumer>
            {
                ({ setOpen, setImage }) => (
                    <a
                        className="image-preview"
                        onClick={() => {
                            setImage(props.full || props.fluid);
                            setOpen(true);
                        }}
                    >
                        <Img {...props} />
                    </a>
                )
            }
        </PreviewContext.Consumer>
    );
};

export default PreviewContext;

