import { graphql, useStaticQuery } from "gatsby";

import { SiteYaml, Edges } from "../types";

interface SiteQuery {
    pages: Edges<SiteYaml & {
        parent: {
            name: string;
        };
    }>;
}

export const useSiteQuery = (): SiteYaml => {
    const { pages } = useStaticQuery<SiteQuery>(
        graphql`
            query {
                pages: allPagesYaml {
                    edges {
                        node {
                            title
                            description
                            socials {
                                name
                                url
                            }
                            footer {
                                title
                                text
                            }

                            parent {
                                ... on File {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    return pages.edges
        .map(({ node }) => node)
        .find(({ parent: { name } }) => name === "site");
};
