import React from "react";
import { Helmet } from "react-helmet";

import { useSiteQuery } from "../utils/hooks";

export interface SEOProps {
    title: string;
    image?: string;
    description?: string;
    keywords?: string[],
    lang?: string;
    twitter?: string;
}

const SEO: React.FC<SEOProps> = ({
    title,
    image,
    description,
    keywords,
    lang = "en",
    twitter,
    children,
}) => {
    const {
        title: siteTitle,
        description: siteDescription,
    } = useSiteQuery();

    description = siteDescription || description;

    return (
        <Helmet
            htmlAttributes={{ lang }}
            //titleTemplate={`%s | ${siteTitle}`}
        >
            <title>{title}</title>

            {description && <meta name="description" content={description} />}

            <meta property="og:site_name" content={siteTitle} />
            <meta property="og:title" content={title} />
            <meta property="og:type" content="website" />
            {description && <meta property="og:description" content={description} />}
            {image && <meta property="og:image" content={image} />}

            <meta name="twitter:card" content={image ? "summary_large_image" : "summary"} />
            <meta name="twitter:title" content={title} />
            {twitter && <meta name="twitter:site" content={twitter} />}

            {keywords?.length && <meta name="keywords" content={keywords.join(", ")} />}

            {children}
        </Helmet>
    );
};

export default SEO;

