import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Love: React.FC = () => {
    return (
        <a href="https://yui.pet" className="love">
            <span>Site made with </span>
            <FontAwesomeIcon icon="heart" className="love__heart" />
            <span> by <span className="love__name">Yui</span></span>
        </a>
    );
};

export default Love;

