import React from "react";
import { graphql, PageProps } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    Edges,
    HasParent,
    KitsYaml,
    RendersYaml,
    SiteYaml,
    VendorsYaml,
} from "../types";

import Layout from "../components/Layout";
import Section from "../components/Section";
import Stitched from "../components/Stitched";
import Container from "../components/Container";
import Slideshow from "../components/Slideshow";
import ColorCard from "../components/ColorCard";
import Vendor from "../components/Vendor";
import LinkButton from "../components/LinkButton";
import { ImagePreview } from "../components/PreviewContext";

import "react-responsive-carousel/lib/styles/carousel.min.css";

export interface HomePageProps extends PageProps {
    data: {
        kits: Edges<KitsYaml & HasParent<{ name: string }>>;
        renders: Edges<RendersYaml>;
        vendors: Edges<VendorsYaml>;
        site: SiteYaml;
    };
}

const HomePage: React.FC<HomePageProps> = ({ data }) => {
    const renders = data.renders.edges.map(({ node }) => node);
    const vendors = data.vendors.edges.map(({ node }) => node);
    const kits = data.kits.edges.map(({ node }) => node);

    const { colors, socials, image } = data.site;

    return (
        <Layout image={image}>
            <Section title="Kits">
                <Stitched className="kits">
                    {
                        kits.map(({ name, image: { childImageSharp } }, i) => (
                            <Container key={i} title={name}>
                                <ImagePreview fluid={childImageSharp.fluid} />
                            </Container>
                        ))
                    }
                </Stitched>
            </Section>
            <Section title="Colors" className="colors">
                {
                    colors
                        .sort((a, b) => a.index - b.index)
                        .map((props, i) => (
                            <ColorCard {...props} key={i} />
                        ))
                }
            </Section>
            <Section title="Renders">
                {
                    renders.map(({ name, images }, i) => (
                        <Slideshow
                            key={i}
                            title={name}
                            images={images.map(({ localFile }) => localFile.childImageSharp.fluid)}
                        />
                    ))
                }
            </Section>
            <Section title="Vendors" className="vendors">
                {
                    vendors.map((vendor, i) => (
                        <Vendor {...vendor} key={i} />
                    ))
                }
            </Section>
            <Section title="Follow us!" className="socials" id="socials">
                {
                    socials
                        .sort((a, b) => a.index - b.index)
                        .map(({ name, url, icon }, i) => (
                            <LinkButton
                                key={i}
                                id={name.toLowerCase()}
                                href={url}
                                icon={<FontAwesomeIcon icon={icon} />}
                            >
                                {name}
                            </LinkButton>
                        ))
                }
            </Section>
        </Layout>
    );
};

export default HomePage;

export const query = graphql`
    query {
        site: pagesYaml {
            image {
                url
                childImageSharp {
                    fluid(
                        maxWidth: 2560
                        #maxHeight: 1440
                        quality: 80
                    ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            colors {
                name
                color
                hex
                text
                index
            }

            socials {
                 name
                 url
                 icon
                 index
            }
        }
        renders: allRendersYaml(
            sort: {
                fields: index
            }
        ) {
            edges {
                node {
                    name
                    images {
                        localFile {
                            childImageSharp {
                                fluid(
                                    maxWidth: 2560
                                    #maxHeight: 1440
                                    quality: 80
                                ) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        vendors: allVendorsYaml(
            sort: {
                fields: index
            }
        ) {
            edges {
                node {
                    name
                    location
                    url
                    currency

                    kits {
                        name
                        price
                    }
                }
            }
        }
        kits: allKitsYaml(
            sort: {
                fields: index
            }
        ) {
            edges {
                node {
                    name
                    image {
                        childImageSharp {
                            fluid(
                                maxWidth: 1920
                                maxHeight: 1200
                                cropFocus: CENTER
                                quality: 80
                            ) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }

                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
    }
`;

