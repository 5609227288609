import React from "react";

export interface ContainerProps {
    className?: string;
    title: string;
}

const Container: React.FC<ContainerProps> = ({ className, children, title }) => {
    return (
        <div className="container">
            <span className="container__title">{title}</span>
            <div className={`container__inner ${className || ""}`.trim()}>
                {children}
            </div>
        </div>
    );
};

export default Container;

