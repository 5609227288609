import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Stitched from "./Stitched";
import LinkButton from "./LinkButton";

export interface VendorPrice {
    name: string;
    price: string;
}

export interface VendorProps {
    name: string;
    location: string;
    currency: string;
    url: string;
    kits: VendorPrice[];
}

const Vendor: React.FC<VendorProps> = ({ name, location, url, kits, currency }) => {
    return (
        <Stitched className="vendor">
            <span className="vendor__location">{location}</span>
            <LinkButton
                href={url}
                className="vendor__title"
                icon={<FontAwesomeIcon icon="external-link-alt" />}
            >
                {name}
            </LinkButton>
            <ul className="vendor__prices">
                {
                    kits.map(({ name, price }, i) => (
                        <li key={i} className="vendor__prices__item">
                            <span className="vendor__prices__name">
                                {name}
                            </span>
                            <span className="vendor__prices__price">
                                {
                                    !price || price.toLowerCase() === "tbd"
                                        ? "TBD"
                                        : `${price} ${currency}`
                                }
                            </span>
                        </li>
                    ))
                }
            </ul>
        </Stitched>
    );
};

export default Vendor;

