import React, { CSSProperties } from "react";

export interface StitchedProps {
    className?: string;
    style?: CSSProperties;
}

const Stitched: React.FC<StitchedProps> = ({ className, children, ...rest }) => {
    return (
        <div {...rest} className={`stitched ${className || ""}`.trim()}>
            <div className="stitched__inner">
                {children}
            </div>
        </div>
    );
};

export default Stitched;
