import React from "react";

import { useSiteQuery } from "../utils/hooks";
import { ImageFile } from "../types";

import { PreviewProvider } from "./PreviewContext";
import SEO, { SEOProps } from "./SEO";
import Hero from "./Hero";
import Footer from "./Footer";
import Love from "./Love";

export interface LayoutProps {
    title?: string;
    text?: string;
    seoProps?: SEOProps;
    image: ImageFile;
}

const Layout: React.FC<LayoutProps> = ({
    title,
    text,
    image,
    seoProps,
    children,
}) => {
    const site = useSiteQuery();
    const { footer } = site;

    title = title || site.title;
    text = text || site.description;

    return (
        <PreviewProvider>
            <SEO
                {...seoProps}
                title={title}
                description={text}
                image={image.url}
            />
            <Hero
                title={title}
                text={text}
                image={image.childImageSharp.fluid}
            />
            <main className="content">
                <div className="content__inner">
                    {children}
                </div>
            </main>
            <Footer {...footer}>
                <Love />
            </Footer>
        </PreviewProvider>
    );
};

export default Layout;

